export default {
	 STATUS_WAIT_APPROVE : 1, //待审批
	 STATUS_APPROVED : 2, //审批通过
	 STATUS_REJECT : 3, //审批拒绝
	 STATUS_BACK : 4, //审批驳回
	 statusMap : {'1':'待审批','2':'通过','3':'拒绝','4':'驳回'},
	 
	 sn : 'cn.jmicro.ext.dataservice.api.IApiDefJMSrv',
	 ns : "ds",
	 v : '0.0.1',
}
